import React from "react"
import AppContext from "@contexts/AppContext"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "@components/Layout"
import PostCard from "@components/PostCard"
import SEO from "@components/SEO"


const Wrapper = styled.div`
  .title {
    margin: 0 0 40px;
    font-size: 40px;
    user-select: none;
    text-align: center;
    color: ${({ theme }) => theme.text.primary};
    vertical-align: text-bottom;
  }
  .breadcrumbs {
    margin: 0 0 15px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        display: inline-flex;
        line-height: 24px;
        :first-child::before {
          content: "";
          padding: 0;
        }
        ::before {
          padding: 0 0.5em;
          color: ${({ theme }) => theme.text.primary};
          content: ">";
        }
        a {
          display: inline-flex;
          color: ${({ theme }) => theme.text.primary};
          text-align: center;
          font-size: 16px;
          text-decoration: none;
          white-space: nowrap;
          svg {
            width: 24px;
            height: 21px;
            fill: ${({ theme }) => theme.text.primary};
            vertical-align: text-bottom;
          }
          :hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    margin: 40px 20px;
  }
`

const MainContent = styled.div`
  .postcardWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    margin-bottom: 40px;
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`

const PaginationWrapper = styled.div`
  .pagination{
    display: flex;
    user-select: none;
    justify-content: center;
    margin: 40px auto;
    a {
      display: inline-flex;
      height: 40px;
      width: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      text-decoration: none;
      font-size: 20px;
      font-weight: 600;
      color: ${({ theme }) => theme.primary.main};
      &:hover {
        transition: all 0.3s ease;
        background: rgba(77, 171, 245, 0.5);
        color: white;
      } 
    }
    .abridgement {
      background: rgba(0, 0, 0, 0) !important;
      &:hover {
        color: ${({ theme }) => theme.primary.main} !important;
      }
    }
    .active {
      color: white;
      background: ${({ theme }) => theme.primary.main} !important;
    }
  }
`


const Category = ({ data, location, pageContext }) => {
  const title = data.site.siteMetadata.title || `Title`
  const slug = pageContext.category

  const posts = data.allMdx.nodes
  const { currentPage, numPages, parent } = pageContext

  const categories = data.site.siteMetadata.categories;
  const categoryObject = categories.find((category => {
    return category.slug === slug
  }))
  const categoryName = categoryObject.name

  const isMother = parent === undefined
  const parentName = isMother ? undefined : categories.find(category => { return category.slug === parent }).name

  const fullSlug = isMother
    ? `/category/${categoryObject.slug}/`
    : `/category/${categoryObject.mom}/${categoryObject.slug}/`

  // pagination
  // ref: https://www.gatsbyjs.com/docs/adding-pagination/
  // ref: https://nickymeuleman.netlify.app/blog/gatsby-pagination
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  let prevPage = String
  let nextPage = String
  if (isMother) {
    prevPage = currentPage - 1 === 1 ? `/category/${slug}/` : `/category/${slug}/${currentPage - 1}`
    nextPage = `/category/${slug}/${currentPage + 1}`
  }
  else {
    prevPage = currentPage - 1 === 1 ? `/category/${parent}/${slug}/` : `/category/${parent}/${slug}/${currentPage - 1}`
    nextPage = `/category/${parent}/${slug}/${currentPage + 1}`
  };
    
  return (
    <AppContext.Provider value={{ title: categoryName, slug: fullSlug }}>
      <Layout location={location} title={title}>
        <Wrapper>
          <div class="title"><span>{categoryName}</span></div>
          <MainContent>
            <SEO
              title={categoryName}
              url={fullSlug}
              description={categoryName}
              image={null}
            />
            <div className="postcardWrapper">
              {posts.map(post => {
                const categoryObject = data.site.siteMetadata.categories.find((category => {
                  if (post.frontmatter.category.length === 1) {
                    return category.slug === post.frontmatter.category[0]
                  }
                  else {
                    return category.slug === post.frontmatter.category[1]
                  }
                }))

                const categoryUrl = categoryObject.mom === null
                  ? `/category/${categoryObject.slug}/`
                  : `/category/${categoryObject.mom}/${categoryObject.slug}/`

                return (
                  <PostCard
                    key={post.fields.slug}
                    title={post.frontmatter.title}
                    category={categoryObject.slug}
                    categoryUrl={categoryUrl}
                    date={post.frontmatter.date}
                    update={post.frontmatter.update}
                    url={post.fields.slug}
                    hero={post.frontmatter.hero === null ? null : post.frontmatter.hero.childImageSharp.fluid}
                  />
                )
              })}
            </div>
          </MainContent>
          <PaginationWrapper>
            <div className="pagination">
              {!isFirst && (
                <Link to={prevPage} rel="prev">
                  &lt;
                </Link>
              )}
              {numPages <= 7 &&
                Array.from({ length: numPages }, (_, i) => (
                  <Link
                    key={`pagination-number${i + 1}`}
                    to={isMother
                      ? `/category/${i === 0 ? `${slug}/` : `${slug}/${i + 1}`}`
                      : `/category/${parent}/${i === 0 ? `${slug}/` : `${slug}/${i + 1}`}`
                    }
                    activeClassName="active"
                  >
                    {i + 1}
                  </Link>
                ))
              }
              {8 <= numPages &&
                <>
                  <Link
                    key={`pagination-number${1}`}
                    to={isMother
                      ? `/category/${slug}/`
                      : `/category/${parent}/${slug}/`
                    }
                    activeClassName="active"
                  >
                    1
              </Link>
                  {currentPage <= 4
                    ?
                    <Link
                      key={`pagination-number${2}`}
                      to={isMother
                        ? `/category/${slug}/2`
                        : `/category/${parent}/${slug}/2`
                      }
                      activeClassName="active"
                    >
                      2
              </Link>
                    :
                    <Link
                      className="abridgement"
                      key={`pagination-number${2}`}
                    >
                      ...
              </Link>
                  }
                  {Array.from({ length: 3 }, (_, i) => (
                    <>
                      {5 > currentPage &&
                        <Link
                          key={`pagination-number${i + 3}`}
                          to={isMother
                            ? `/category/${slug}/${i + 3}`
                            : `/category/${parent}/${slug}/${i + 3}`
                          }
                          activeClassName="active"
                        >
                          {i + 3}
                        </Link>
                      }
                      {(5 <= currentPage && currentPage <= numPages - 4) &&
                        <Link
                          key={`pagination-number${i + currentPage - 1}`}
                          to={isMother
                            ? `/category/${slug}/${i + currentPage - 1}`
                            : `/category/${parent}/${slug}/${i + currentPage - 1}`
                          }
                          activeClassName="active"
                        >
                          {i + currentPage - 1}
                        </Link>
                      }
                      {currentPage > numPages - 4 &&
                        <Link
                          key={`pagination-number${i + numPages - 4}`}
                          to={isMother
                            ? `/category/${slug}/${i + numPages - 4}`
                            : `/category/${parent}/${slug}/${i + numPages - 4}`
                          }
                          activeClassName="active"
                        >
                          {i + numPages - 4}
                        </Link>
                      }
                    </>
                  ))}
                  {numPages - 3 <= currentPage
                    ?
                    <Link
                      key={`pagination-number${numPages - 1}`}
                      to={isMother
                        ? `/category/${slug}/${numPages - 1}`
                        : `/category/${parent}/${slug}/${numPages - 1}`
                      }
                      activeClassName="active"
                    >
                      {numPages - 1}
                    </Link>
                    :
                    <Link
                      className="abridgement"
                      key={`pagination-number${numPages - 1}`}
                    >
                      ...
              </Link>
                  }
                  <Link
                    key={`pagination-number${numPages}`}
                    to={isMother
                      ? `/category/${slug}/${numPages}`
                      : `/category/${parent}/${slug}/${numPages}`
                    }
                    activeClassName="active"
                  >
                    {numPages}
                  </Link>
                </>
              }
              {!isLast && (
                <Link to={nextPage} rel="next">
                  &gt;
                </Link>
              )}
            </div>
          </PaginationWrapper>
          {/* ぱんくずリスト */}
          <nav className="breadcrumbs">
            <ul itemScope={true} itemType="https://schema.org/BreadcrumbList">
              <li key="1" itemProp="itemListElement" itemScope={true} itemType="https://schema.org/ListItem">
                <Link key="home" itemProp="item" to={`/`}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
                  </svg>
                  <span itemProp="name">Home</span>
                </Link>
                <meta itemProp="position" content="1" />
              </li>
              {!isMother &&
                <li key="2" itemProp="itemListElement" itemScope={true} itemType="https://schema.org/ListItem">
                  <Link key={parent} itemProp="item" to={`/category/${parent}/`}>
                    <span itemProp="name">{parentName}</span>
                  </Link>
                  <meta itemProp="position" content="2" />
                </li>
              }
              <li key={`${!isMother ? 3 : 2}`} itemProp="itemListElement" itemScope={true} itemType="https://schema.org/ListItem">
                <Link key={slug} itemProp="item" to={`/category/${!isMother ? `${parent}/` : ""}${slug}/`}>
                  <span itemProp="name">{categoryName}</span>
                </Link>
                <meta itemProp="position" content={`${!isMother ? 3 : 2}`} />
              </li>
            </ul>
          </nav>
        </Wrapper>
      </Layout>
    </AppContext.Provider>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage(
    $category: String, $skip: Int!, $limit: Int!, $image: String,
    ) {
    site {
      siteMetadata {
        title
        categories {
          name
          slug
          mom
        }
      }
    }
    file(relativePath: { eq: $image }) {
      publicURL
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { category: { eq: $category } } 
        fields: { type: { eq: "blog" } }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "YYYY-MM-DD")
          update(formatString: "YYYY-MM-DD")
          description
          category
          hero {
            childImageSharp {
              fluid(maxWidth: 1350) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
